import PrevPageBtn from '../buttons/prev-page-button';
import NextPageBtn from '../buttons/next-page-button';
import SearchBox from './search-box';
import LibraryTabBtn from './library-tab-btn';
import{signInWithGoogle, isLoggedOn} from '../../firebase'

import styles from './topnav.module.css';

let logged=true;

function Topnav({search = false, tabButtons = false}) {

    
    if(isLoggedOn==true){
    return (
      <nav className={styles.Topnav}>
          <div>
                <span>
                   
                    { search ? <SearchBox /> : '' }
                    { tabButtons ? <LibraryTabBtn /> : '' }
                </span>
                <span>

                    <button onClick={signInWithGoogle} className={styles.ProfileBtn}>
                    
                        
                    </button>
                </span>
          </div>
      </nav>
    );
    }
    if (isLoggedOn==false){
        return (
            <nav className={styles.Topnav}>
                <div>
                      <span>
                         
                          { search ? <SearchBox /> : '' }
                          { tabButtons ? <LibraryTabBtn /> : '' }
                      </span>
                      <span>
      
                          <button onClick={signInWithGoogle} className={styles.ProfileBtn}>
                                <img width="20" height="20" src={localStorage.getItem("profilePic")}/>
                              {localStorage.getItem("name")}
                          </button>
                      </span>
                </div>
            </nav>
          );
    }
}
  
export default Topnav;