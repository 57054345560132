export const PLAYLIST = [
	{
		index: "0",
		type: "Album",
		title: "Experience",
		link: "Experience",
		imgUrl:
			"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/Experience%20artwork%20.jpg&w=750&q=90",
		hoverColor: "rgb(224, 112, 16)",
		artist: "Henry Czar",
		playlistBg: "rgb(224, 112, 16)",
		playlistData: [
			{
				index: "1",
				songName: "Experience",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/Experience%20artwork%20.jpg&w=750&q=90",
				songArtist: "Henry Czar",
				link:
					"https://firebasestorage.googleapis.com/v0/b/icarus-music.appspot.com/o/Imzie%20-%20Munafera%20Fashion%20(Prod.%20Zupah).mp3?alt=media&token=4e7c4541-1ca0-440b-a67c-f9647692016a",
				trackTime: "3:19",
			},
			{
				index: "2",
				songName: "Kukula",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/IMG-20220605-WA0018.jpg&w=750&q=90",
				songArtist: "Henry Czar",
				link:
					"https://www.malawi-music.com/songs/Kukula.mp3",
				trackTime: "3:35",
			},
			{
				index: "3",
				songName: "Siyomweyo",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/henele.jpg&w=750&q=90",
				songArtist: "Henry Czar",
				link:
					"https://www.malawi-music.com/songs/Siyomweyo.mp3",
				trackTime: "3:05",
			},
			
		],
	},
	{
		index: "1",
		type: "playlist",
		title: "Ndifunsa",
		link: "Macelba",
		imgUrl:
			"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/macelba%20ndifunsa%20art.jpg&w=650&q=90",
		hoverColor: "rgb(216, 24, 40)",
		artist: "Macelba",
		playlistBg: "rgb(216, 24, 40)",
		playlistData: [
			{
				index: "1",
				songName: "Ndifunsa",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/macelba%20ndifunsa%20art.jpg&w=650&q=90",
				songArtist: "Macelba",
				link:
					"https://www.malawi-music.com/songs/Macelba%20-%20Ndifunsa%20ft%20Skeffa%20Chimoto%20&%20Joe%20Kellz.mp3",
				trackTime: "4:28",
			},
			{
				index: "2",
				songName: "Nawe",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/nawe%20janta.jpg&w=650&q=90",
				songArtist: "Macelba",
				link:
					"https://www.malawi-music.com/songs/Macelba%20-%20Nawe%20ft%20Janta.mp3",
				trackTime: "04:32",
			},
			{
				index: "3",
				songName: "Khonfi",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/celba.jpeg&w=650&q=90",
				songArtist: "Macelba",
				link:
					"https://www.malawi-music.com/songs/Macelba%20-%20Khonfi%20ft%20AK%20On%20Tha%20Board%20(Prod.%20Janta).mp3",
				trackTime: "03:21",
			},
		],
	},
	{
		index: "2",
		type: "playlist",
		title: "Cha Nayo",
		link: "phyzix",
		imgUrl:
			"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/WhatsApp%20Image%202022-08-15%20at%2011.43.04%20AM.jpeg&w=650&q=90",
		hoverColor: "rgb(232, 24, 48)",
		artist: "Phyzix.",
		playlistBg: "rgb(232, 24, 48)",
		playlistData: [
			{
				index: "1",
				songName: "Cha Nayo",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/WhatsApp%20Image%202022-08-15%20at%2011.43.04%20AM.jpeg&w=750&q=90",
				songArtist: "Phyzix",
				link:
					"https://www.malawi-music.com/songs/Phyzix%20-%20Cha%20Nyo!%20ft.%20Dan%20Lu%20(Prod.%20DJ%20Ok%20&%20Martin%20Anjelz).mp3",
				trackTime: "03:26",
			},
			{
				index: "2",
				songName: "Ma P",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/phyzix%20ma%20p%20art.jpeg&w=750&q=90",
				songArtist: "Phyzix",
				link:
					"https://www.malawi-music.com/songs/Phyzix%20-%20Ma%20P%20(Prod.%20RichardBilly%20&%20Stich%20Fray).mp3",
				trackTime: "03:14",
			},
			{
				index: "3",
				songName: "Streets",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/phyzix%20streets.jpeg&w=750&q=90",
				songArtist: "Phyzix ft Malinga",
				link:
					"https://www.malawi-music.com/songs/Streets%20-%20Phyzix%20Ft%20Malinga%20Mafia.mp3",
				trackTime: "03:56",
			},
		],
	},
	{
		index: "3",
		type: "playlist",
		title: "Daily Mix 1",
		link: "dailymix1",
		imgUrl:
			"https://dailymix-images.scdn.co/v2/img/ab67616d0000b2731cfa3aa970ec68a11a015c7c/1/tr/default",
		hoverColor: "rgb(192, 32, 40)",
		artist: "Murder King, Black Tooth, The Madcap ve daha fazlası",
		playlistBg: "rgb(136, 8, 24)",
		playlistData: [
			{
				index: "1",
				songName: "Fiasco",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851088ed5ba2a9d32608d8942f2",
				songArtist: "Murder King",
				link:
					"https://p.scdn.co/mp3-preview/2680aa5c61fede26f4cc24edfa17f7b18edfac38?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "2",
				songName: "Arıza",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851cc462180ef86e33de2265441",
				songArtist: "Malt",
				link:
					"https://p.scdn.co/mp3-preview/95f66bb0428213025201b6139a9400e7bd2f6050?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "3",
				songName: "Yukarıya Bak",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851e0cfce61b45ba6064a07fb0e",
				songArtist: "Ogün Sanlısoy",
				link:
					"https://p.scdn.co/mp3-preview/182320dad925b62fe2dc6332213efd886de06389?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
		],
	},
	{
		index: "4",
		type: "playlist",
		title: "Çalmaya Doyamadıkların",
		link: "calmayadoyamadiklarin",
		imgUrl:
			"https://daily-mix.scdn.co/covers/backtracks/PZN_Repeat_Rewind2_LARGE-tr.jpg",
		hoverColor: "rgb(240, 56, 168)",
		artist: "Çalmaya doyamadığın parçalar",
		playlistBg: "rgb(240, 56, 168)",
		playlistData: [
			{
				index: "1",
				songName: "Irıe",
				songimg:
					"https://i.scdn.co/image/ab67616d000048515ac8e878a5af6bebbcd83cd8",
				songArtist: "Sattas",
				link:
					"https://p.scdn.co/mp3-preview/8b54586a78acbc69fb19bdcd3cb47914b3d3971b?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "2",
				songName: "I Shot The Sheriff",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851d752956b8a82ffa07baa835e",
				songArtist: "Eric Clapton",
				link:
					"https://p.scdn.co/mp3-preview/1d3fc3dcc454fb4cab86f183e468617a21c3bfdd?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "3",
				songName: "Before You Accuse Me",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851ed4b2a6ea7d8a04f39be0be0",
				songArtist: "Eric Clapton",
				link:
					"https://p.scdn.co/mp3-preview/52cba5b168dae7b1d8e30d3873fc709fc2012a5f?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
		],
	},
	{
		index: "5",
		type: "album", 
		title: "Eli Njuchi Experience",
		link: "Eli",
		imgUrl:
			"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/1652180470_unnamed.jpg&w=750&q=90",
		hoverColor: "rgb(160, 24, 32)",
		artist: "Eli Njuchi",
		playlistBg: "rgb(160, 24, 32)",
		playlistData: [
			{
				index: "1",
				songName: "GuGuGu",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/1652180470_unnamed.jpg&w=750&q=90",
				songArtist: "Eli Njuchi",
				link:
					"https://www.malawi-music.com/songs/Gugugu.mp3",
				trackTime: "03:30",
			},
			{
				index: "2",
				songName: "Zitaye",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/elizitaye.jpg&w=750&q=90",
				songArtist: "Eli Njuchi",
				link:
					"https://www.malawi-music.com/songs/Eli%20Njuchi%20-%20Zitaye.mp3",
				trackTime: "02:41",
			},
			{
				index: "3",
				songName: "Love Since Day One",
				songimg:
					"https://www.malawi-music.com/timthumb.php?src=http://www.malawi-music.com/images/albums/WhatsApp%20Image%202019-02-20%20at%2018.40.00.jpg&w=750&q=90",
				songArtist: "Eli Njuchi",
				link:
					"https://www.malawi-music.com/songs/Eli%20Njuchi%20-%20Love%20Since%20Day%20One%20ft%20Lirongwe%20&%20Rico.mp3",
				trackTime: "03:02",
			},
		],
	},
	{
		index: "6",
		type: "playlist",
		title: "Daily Mix 2",
		link: "dailymix2",
		imgUrl:
			"https://dailymix-images.scdn.co/v2/img/04cd9bccf59e8061f13e779d1001975a60fb837a/2/tr/default",
		hoverColor: "rgb(136, 152, 152)",
		artist:
			"Arch Enemy, Judas Priest, Five Finger Death Punch ve daha fazlası",
		playlistBg: "rgb(136, 152, 152)",
		playlistData: [
			{
				index: "1",
				songName: "The World Is Yours",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851f22accdfcf008be55b063aae",
				songArtist: "Arch Enemy",
				link:
					"https://p.scdn.co/mp3-preview/f8bef3ca1ebf7c7b72cb61e6a35053f801fa66d9?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "2",
				songName: "Cocaine",
				songimg:
					"https://i.scdn.co/image/ab67616d000048515e7464d9d8a25b2bf74b782a",
				songArtist: "Eric Clapton",
				link:
					"https://p.scdn.co/mp3-preview/4272f788ea24f7dd3ce9a5ed8bb5dcf125f88844?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "3",
				songName: "Nemesis",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851dcbd25c605dddb32022cecba",
				songArtist: "Arch Enemy",
				link:
					"https://p.scdn.co/mp3-preview/2591d93eec734f134accdda26634738069dc7238?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
		],
	},
	{
		index: "7",
		type: "playlist",
		title: "Daily Mix 3",
		link: "dailymix3",
		imgUrl:
			"https://dailymix-images.scdn.co/v2/img/c23701238e46876969ea3b694e9b42b8db133b91/3/tr/default",
		hoverColor: "rgb(83, 83, 83)",
		artist: "Amaranthe, Ad Infinitum, Metalite ve daha fazlası",
		playlistBg: "rgb(83, 83, 83)",
		playlistData: [
			{
				index: "1",
				songName: "See You In Hell",
				songimg:
					"https://i.scdn.co/image/ab67616d000048515ef2b5cf848e8cf0e656d10b",
				songArtist: "Ad Infinitum",
				link:
					"https://p.scdn.co/mp3-preview/b8d5cbcfc074be8f58c88f0fa0604ab9986c49b5?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "2",
				songName: "Demons",
				songimg:
					"https://i.scdn.co/image/ab67616d000048515ef2b5cf848e8cf0e656d10b",
				songArtist: "Ad Infinitum",
				link:
					"https://p.scdn.co/mp3-preview/8f66d19e71277f3ecdca11f851b5a588b47802d4?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "3",
				songName: "Digital World",
				songimg:
					"https://i.scdn.co/image/ab67616d000048517e1fea351d3673f4f94f5c8c",
				songArtist: "Amaranthe",
				link:
					"https://p.scdn.co/mp3-preview/466e3e1522dbeb23d6876bb84a0d60ce77bd0ed6?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
		],
	},
	{
		index: "8",
		type: "podcast",
		title: "O Tarz Mi?",
		link: "otarzmi",
		imgUrl:
			"https://i.scdn.co/image/dacd32a7203aeb7b7910d8880943a939c8006b0a",
		hoverColor: "rgb(83, 83, 83)",
		artist: "otarzmi",
		playlistBg: "rgb(83, 83, 83)",
		playlistData: [
			{
				index: "1",
				songName: "S06E36-Suç Makinasi Ayağınıza Geldi",
				songimg:
					"https://i.scdn.co/image/dacd32a7203aeb7b7910d8880943a939c8006b0a",
				songArtist: "otarzmi",
				link: "https://episodes.buzzsprout.com/gozvh84ncp95hmvgapex1dk5pjwx?",
				trackTime: "41:25",
			},
		],
	},
	{
		index: "9",
		type: "playlist",
		title: "Haftalık Keşif",
		link: "haftalikkesif",
		imgUrl:
			"https://newjams-images.scdn.co/v3/discover-weekly/2lV5Qwmpe91f0dhmWfev1weP_q_HziA_LM11FaIr4Bu68O0yYXq-XPsfv6Avul-vaOKC9f6srSRgph0fDqROxs2RF1snIhpYpjxK5JjdyiQ=/MTM6MDA6MTBUMzItMzAtMQ==/default",
		hoverColor: "rgb(240, 144, 184)",
		artist:
			"Haftalık taptaze müzik derlemen. Yeni ve ender duyulan müziklerin tadını çıkar. Her Pazartesi güncellenir.",
		playlistBg: "rgb(240, 144, 184)",
		playlistData: [
			{
				index: "1",
				songName: "Entertain You",
				songimg:
					"https://i.scdn.co/image/ab67616d0000485138256ec6ec7c0e9cd3bf3e92",
				songArtist: "Within Temptation",
				link:
					"https://www.malawi-music.com/songs/Experience.mp3",
				trackTime: "0:30",
			},
			{
				index: "2",
				songName: "Stones",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851635c9fa1d8db5af7aac19a29",
				songArtist: "Marko Hietala",
				link:
					"https://p.scdn.co/mp3-preview/dce5967bf69ea027f54ea67fe59984f3eb47a21f?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
			{
				index: "3",
				songName: "Mein Herz brennt",
				songimg:
					"https://i.scdn.co/image/ab67616d00004851ce965578345576f348e20b96",
				songArtist: "Eleine",
				link:
					"https://p.scdn.co/mp3-preview/693c91d913b1512e4daa158f3237db40079a3f94?cid=6d083ea30aaa46428fbf1590d31f6750",
				trackTime: "0:30",
			},
		],
	},
];

export const MUSICLINKS = [
	{
		link:
			"https://server1.indiryuklemp3.org/mp3_files/yalan-t3OjaAeo6z-0.mp3",
	},
	{
		link:
			"https://server1.indiryuklemp3.org/mp3_files/a9b438cef5490afe36b0d0cb18ba9385.mp3",
	},
	{
		link:
			"https://server1.indiryuklemp3.org/mp3_files/a9b438cef5490afe36b0d0cb18ba9385.mp3",
	},
	{
		link:
			"https://server1.indiryuklemp3.org/mp3_files/a9b438cef5490afe36b0d0cb18ba9385.mp3",
	},
];

export const SEARCHCARDS = [
	{
		bgcolor: 'rgb(160, 195, 210)',
		imgurl: 'https://t.scdn.co/images/68433b0ee5b5465b8e926c42b84cbcdb.jpeg',
		title: 'Prepared for You',
	},
	{
		bgcolor: 'rgb(75, 145, 125)',
		imgurl: 'https://t.scdn.co/images/4b7472015a274eadbc00119f5141e548.jpeg',
		title: 'Lists',
	},
	{
		bgcolor: 'rgb(111, 111, 111)',
		imgurl: 'https://t.scdn.co/images/ffbc60a80ba64554ac09130827c43d06.jpeg',
		title: 'Metal',
	},
	{
		bgcolor: 'rgb(235, 30, 50)',
		imgurl: 'https://t.scdn.co/images/31c85ae6fec34a16927ef28a7a88e97b.jpeg',
		title: 'Rock',
	},
	{
		bgcolor: 'rgb(195, 240, 200)',
		imgurl: 'https://t.scdn.co/images/d355f48a90b64c25b6e004179a596e51.jpeg',
		title: 'Pop',
	},
	{
		bgcolor: 'rgb(160, 195, 210)',
		imgurl: 'https://t.scdn.co/images/0f2f4c71c9714cc5b757f7bb3559fcdb.jpeg',
		title: 'Jazz',
	},
	{
		bgcolor: 'rgb(245, 155, 35)',
		imgurl: 'https://t.scdn.co/images/9676cef74ec147a48607c737c4f93943.jpeg',
		title: 'Hip Hop',
	},
	{
		bgcolor: 'rgb(160, 195, 210)',
		imgurl: 'https://t.scdn.co/images/acc7b5d7b1264d0593ec05c020d0a689.jpeg',
		title: 'New Releases',
	},
	{
		bgcolor: 'rgb(245, 155, 35)',
		imgurl: 'https://t.scdn.co/images/ad4d5c268a214f78920517e76e6ed107.jpeg',
		title: 'Radio',
	},
	{
		bgcolor: 'rgb(180, 155, 200)',
		imgurl: 'https://t.scdn.co/images/d0fb2ab104dc4846bdc56d72b0b0d785.jpeg',
		title: 'Discover',
	},
	{
		bgcolor: 'rgb(255, 100, 55)',
		imgurl: 'https://t.scdn.co/images/60075fbc12304968941c7445a971fb9d.jpeg',
		title: 'Concerts',
	},
	{
		bgcolor: 'rgb(80, 155, 245)',
		imgurl: 'https://t.scdn.co/images/baf681efc57145e180589e027496b101.jpeg',
		title: 'Mood',
	},
	{
		bgcolor: 'rgb(245, 155, 35)',
		imgurl: 'https://t.scdn.co/images/194b9d722de34cefb3b716c653b4526b.png',
		title: 'House',
	},
	{
		bgcolor: 'rgb(160, 195, 210)',
		imgurl: 'https://t.scdn.co/images/2470fe22d03a4375a9501dce8cfb2b8c.jpeg',
		title: 'Chill',
	},
	{
		bgcolor: 'rgb(180, 155, 200)',
		imgurl: 'https://t.scdn.co/images/cf23ab0343e047daa88703e21c01a4aa.jpeg',
		title: 'Focus',
	}
]
