// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import{getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBIJbUtttXFTdsvdOoAyJKv8pez4aN4er0",
  authDomain: "icarus-music.firebaseapp.com",
  projectId: "icarus-music",
  storageBucket: "icarus-music.appspot.com",
  messagingSenderId: "984653309667",
  appId: "1:984653309667:web:0b414252843faa6fac87d6",
  measurementId: "G-ZJS4ERRVRL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth=getAuth(app);

const provider= new GoogleAuthProvider()

export const isLoggedOn=false;
export const signInWithGoogle=()=>{
    signInWithPopup(auth,provider).then((result)=>{
        const name=result.user.displayName;
        const profilePic=result.user.photoURL;
        

        localStorage.setItem("name",name)
        localStorage.setItem("profilePic",profilePic)
        isLoggedOn=true
        
    
    }).catch((error)=>{
        console.log(error);
    })
};